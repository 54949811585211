/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import PageWrapper from "../../layouts/PageWrapper"
import PostCard from "../../components/PostCard"

export default class BlogPage extends Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <PageWrapper>
        <Helmet>
          <title>Blog - EA Technique</title>
        </Helmet>
        <section className="hero is-primary is-bold">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title">Blog</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          {/* <PostCard posts={posts} /> */}
          <div className="section is-paddingless">
            {posts
              .filter((post) => post.node.frontmatter.title === "1")
              .map(({ node: post }) => (
                <div className="news_item is-size-5" key={post.id}>
                  <p className="news_item-date">{post.frontmatter.date}</p>
                  <p>
                    <Link
                      className="news_item-heading is-size-4 has-text-weight-bold"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </p>
                  <p className="news_item-excerpt">{post.excerpt}</p>
                  <p className="news_item-link">
                    <Link to={post.fields.slug}>Keep Reading →</Link>
                  </p>
                </div>
              ))}
          </div>
        </section>
      </PageWrapper>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const blogPageQuery = graphql`
  query BlogPage {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            cover
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
